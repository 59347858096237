import {
  ConfigService,
  defaultConfig,
  fetchJSON,
  HotelRoomView,
  LoggerFactory
} from '@ibe/services';
import { appService, initAuth } from '@ibe/components';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import pl from 'date-fns/locale/pl';
import cs from 'date-fns/locale/cs';
import da from 'date-fns/locale/da';
import ru from 'date-fns/locale/ru';
import { ConfigurationModel as TupvidConfigurationModel } from '@ibe/tupvid-services';
import { configurationService as tupvidConfigurationService } from '@ibe/tupvid-components';
import { enableTracking } from '../Tracking/setup';
import '../Tracking/trackingSubscriptions';
import getExternalConfig from './externalConfigs';
import { WidgetProps } from '../widgets/AppWidget';
import Cookie from '../Cookie/Cookie';
import { cookieConsentChangeEvent } from '../Cookie/CookieEvents';
import { getDefaultLocale, registerLocale, setDefaultLocale } from 'react-datepicker';
import { Language, languages } from '../Translations/index';
import getTupvidExternalConfig from './tupvidExternalConfigs';
import MegConfigModel from './MegConfigModel';

const logger = LoggerFactory.get('config');

// ATTENTION - there is a useConfig() hook - you probably do not need to import anything from here :-)

const customTupvidConfig: Partial<TupvidConfigurationModel> = {
  apiUrl: 'http://localhost:8082/',
  configUrl: '/tupvid-config.json'
};

const customConfig: Partial<MegConfigModel> = {
  defaultLanguage: 'en',
  apiUrl: 'http://localhost:8080',
  configUrl: '/config.json',
  displayFormatDate: {
    en: 'DD/MM/YYYY',
    de: 'DD/MM/YYYY',
    de_DE: 'DD.MM.YYYY',
    en_GB: 'DD/MM/YYYY',
    en_US: 'MM/DD/YYYY'
  },
  displayFormatDateTime: {
    en: 'DD/MM/YYYY HH:mm',
    de: 'DD/MM/YYYY HH:mm',
    de_DE: 'DD.MM.YYYY HH:mm',
    en_GB: 'DD/MM/YYYY HH:mm',
    en_US: 'MM/DD/YYYY HH:mm'
  },
  displayFormatDateShort: {
    en: 'DD MMM YY',
    de: 'DD MMM YY',
    de_DE: 'DD MMM YY',
    en_GB: 'DD MMM YY',
    en_US: 'DD MMM YY'
  },
  calendar: {
    weekStartsOn: {
      'en-US': 0 /* Sunday */
    }
  },
  sessionKeyTabsShared: false,
  session: {
    expirationTimeNotificationOffset: 2,
    guestExpirationTimeNotificationOffset: 1,
    expirationTime: 60
  },
  theme: 'legoland',
  components: {
    voucherBuyerForm: {
      showFiscalCode: false,
      showState: true
    },
    voucherPaymentSection: {
      showTaxes: false
    },
    hotelRoomView: HotelRoomView.ROOMCONTAINSALLROOMCONFIG,
    paxRoomSelection: {
      disableButton: false
    }
  },
  forms: {
    excludeSalutations: ['CHD', 'INF', 'CheckFieldsButtonsAddressCodes'],
    showConfirmEmailField: true,
    registerForm: {
      showAgencyNumberField: true
    }
  },
  traveler: {
    maxInfantAge: 2,
    maxChildAge: 17,
    genderValues: [],
    mockAvailable: false,
    adjustRoomContainerToConfig: true
  },
  showCheckoutDocuments: false,
  roundPricesToWholeNumber: 'ROUND_UP_MODE',
  defaultCurrency: 'USD',
  currency: 'USD',
  datePickerMinDateOffset: 0,
  roomsLeftMaxToDisplay: 5,
  offersLeftMaxToDisplay: 5,
  enableMoveTicketLogic: true,
  hideMoveTicketOnHotelList: false,
  hideSpecialRequests: false,
  paymentStyleUrl: 'http://top-llf-test.ops.local:9891/pconnect/css/adyen/client.css',
  hotelListResultsQuantity: 50,
  availabilityCalendarThreshold: 5,
  cachePackageComponentsRequests: false,
  hidePaymentInformation: false,
  showTravelPlan: false,
  multipleTimeSlots: true,
  showWalletLinks: false,
  sanitizeConfig: {
    allowIframes: true
  },
  conference: {
    entryCode: 'EC',
    taxNumberMaxLength: 30
  }
};

export const configService = new ConfigService<MegConfigModel>(MegConfigModel, defaultConfig);
configService.mergeIntoConfig(customConfig);

const initTracking = (config: MegConfigModel): void => {
  Cookie.register(() => {
    cookieConsentChangeEvent.broadcast();
  });

  enableTracking(config);

  cookieConsentChangeEvent.subscribe(() => enableTracking(config));
};

export const boot = async (isWidget: boolean, externalParams?: WidgetProps): Promise<void> => {
  // TUPVID config
  let tupvidExternalConfig = {};
  if (!!externalParams) {
    const { ...extractedParams } = externalParams;
    tupvidExternalConfig = getTupvidExternalConfig(extractedParams, tupvidExternalConfig);
    tupvidConfigurationService.hydrate(tupvidExternalConfig);
  } else {
    tupvidConfigurationService.hydrate(customTupvidConfig);
    const tupvidUrl = tupvidConfigurationService.configuration.configUrl;
    if (tupvidUrl) {
      try {
        const externalConfig = (await fetchJSON(tupvidUrl)) as Partial<TupvidConfigurationModel>;
        tupvidConfigurationService.hydrate(externalConfig);
      } catch (err) {
        logger.log(err);
      }
    }
  }

  // App config
  const url = configService.get().configUrl;

  let externalConfig: Partial<MegConfigModel> = {};
  let externalConfigFetched: Partial<MegConfigModel> = {};
  if (!!externalParams) {
    const { stickyElementsOffset, ...extractedParams } = externalParams;
    externalConfig = getExternalConfig(extractedParams, externalConfig);
    configService.mergeIntoConfig(externalConfig);

    externalConfigFetched = externalConfig;
  } else if (url) {
    externalConfig = (await fetchJSON(url)) as Partial<MegConfigModel>;
    configService.mergeIntoConfig(externalConfig);
  }

  const configurationEndpointUrl = ((await fetchJSON(
    getConfigurationEndpointUrl()
  )) as unknown) as string;

  if (configurationEndpointUrl) {
    externalConfig = (await fetchJSON(configurationEndpointUrl)) as Partial<MegConfigModel>;
  }

  if (isWidget) {
    externalConfig = {
      ...externalConfig,
      stickyElementsOffset: externalParams?.stickyElementsOffset || {
        mobile: {
          breakpoint: 0,
          offset: 80
        },
        tablet: {
          breakpoint: 1025,
          offset: 120
        },
        desktop: {
          breakpoint: 1200,
          offset: 140
        }
      }
    };
  }
  externalConfig = {
    ...externalConfig,
    outsideElementsContainerId: 'iso'
  };
  configService.mergeIntoConfig(externalConfig);

  if (!!externalConfigFetched) {
    const { configUrl, apiUrl, translationsUrl, ...rest } = externalConfigFetched;
    externalConfigFetched = {
      ...rest,
      outsideElementsContainerId: 'iso'
    };
    configService.mergeIntoConfig(externalConfigFetched);
  }
  if (!!configService.get().defaultCountry) {
    configService.mergeIntoConfig({
      defaultCountry: configService.get().defaultCountry?.toUpperCase()
    });
  }

  const config = configService.get();
  config.currency = config.defaultCurrency;

  await appService.hydrate(config);
  await initAuth(config);

  initTracking(config);

  function getConfigurationEndpointUrl(): string {
    const backendUrl = configService.get().apiUrl;
    return backendUrl + '/cms/fetchConfiguration';
  }
};

export const initLanguage = (lang: string, fallbackLang: string): string => {
  registerLocale('de', de);
  registerLocale('en', en);
  registerLocale('en-GB', en);
  registerLocale('en-US', enUS);
  registerLocale('pl', pl);
  registerLocale('es', es);
  registerLocale('cs', cs);
  registerLocale('fr', fr);
  registerLocale('it', it);
  registerLocale('pt', pt);
  registerLocale('da', da);
  registerLocale('ru', ru);
  const langOnly = lang.substring(0, 2);
  if (languages.includes(lang as Language)) {
    setDefaultLocale(lang);
  } else if (languages.includes(langOnly as Language)) {
    setDefaultLocale(langOnly);
  } else {
    setDefaultLocale(fallbackLang);
  }

  return getDefaultLocale();
};
