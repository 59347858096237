import React, { Suspense, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { useMount } from 'react-use';
import { observer } from 'mobx-react';
import { Overlay, Theme, ThemeProvider, useAppService, useConfig, useTheme } from '@ibe/components';
import { LoggerFactory } from '@ibe/services';
import App from '../App';
import { boot, initLanguage } from '../Config/config';
import initI18next from '../Translations/setup';
import { getTheme } from '../Util/utils';
import { PageUrl } from '../pages';
import ExternalParams, {
  ExternalParamsContextProps,
  ExternalSearchProps
} from '../Config/ExternalParamsContext';
import { ServiceParamsTransformer } from '../Pages/Params/ServiceParamsTransformer';
import { ExternalSearchWidgetParams, externalSearchWidgetSessionKey } from './IBESearchWidget';
import useCms from '../Hooks/useCms';
import Workflow from '../Models/CMS/Workflow';
import i18next from 'i18next';
import { LoadingOverlay } from '../Components/Spinner/LoadingOverlay';

const logger = LoggerFactory.get('AppWidget');

export type WidgetProps = ExternalParamsContextProps & { defaultUrl: string } & ExternalSearchProps;

const AppWidget = observer((props: WidgetProps) => {
  const {
    widgetSessionKeyPrefix: keyPrefix,
    packageCode,
    hotelCode,
    salesChannel,
    workflow,
    paxDefinition,
    searchParams: ibeSearchParams
  } = props;
  const cmsService = useCms();
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);
  const [theme, setTheme] = useState<{} | Theme>(useTheme());
  const [defaultUrl, setDefaultUrl] = useState<string>(
    props.defaultUrl ?? PageUrl.PACKAGE + '?wrk=public'
  );
  const appService = useAppService();
  const config = useConfig();
  const widgetSessionKeyPrefix = keyPrefix || document.body.id || 'prefix';

  useMount(async () => {
    await initI18next(props);
    await boot(true, props);
    const lang = initLanguage(i18next.language, config.defaultLanguage);
    appService.setLang(lang);
    // set currency from widget view to the context ???
    const appTheme = await getTheme();
    setTheme(appTheme);

    let localServiceWidgetParams: ExternalSearchWidgetParams | undefined = undefined;
    if (packageCode && salesChannel && workflow) {
      let localWorkflow: Workflow | undefined = undefined;
      try {
        localWorkflow = await cmsService.fetchPackageWorkflow(workflow);
      } catch (err) {
        logger.error(err);
      }
      localServiceWidgetParams = {
        serviceSearchParams: {
          code: packageCode,
          salesChannelCode: salesChannel,
          wrk: workflow,
          hotelCode,
          ...ibeSearchParams
        },
        searchWidgetParams: {
          defaultAdults: paxDefinition?.defaultAdults || localWorkflow?.numberOfAdults,
          maxAdults: paxDefinition?.maxAdults || localWorkflow?.maxNumberOfAdults,
          maxChildren: paxDefinition?.maxChildren || localWorkflow?.maxNumberOfChildren,
          maxChildAge: paxDefinition?.maxChildAge || localWorkflow?.maxChildAge,
          maxDuration: paxDefinition?.maxDuration || localWorkflow?.maxTripDuration
        }
      };
    }

    const searchWidgetParams = externalSearchWidgetSessionKey.get();
    if (localServiceWidgetParams || searchWidgetParams) {
      const widgetParams = (localServiceWidgetParams ||
        searchWidgetParams) as ExternalSearchWidgetParams;
      const urlParams = ServiceParamsTransformer.encode(
        widgetParams.serviceSearchParams,
        widgetParams.searchWidgetParams
      );
      setDefaultUrl(`${PageUrl.HOTEL_LIST}?${urlParams}`);
      externalSearchWidgetSessionKey.clear();
    }

    setBootstrappingFinished(true);
  });

  return bootstrappingFinished ? (
    <ThemeProvider theme={theme}>
      <div id="iso">
        <Suspense fallback={<LoadingOverlay />}>
          <MemoryRouter>
            <ExternalParams {...props} widgetSessionKeyPrefix={widgetSessionKeyPrefix}>
              <App mode="WIDGET" defaultUrl={defaultUrl} />
            </ExternalParams>
          </MemoryRouter>
        </Suspense>
      </div>
    </ThemeProvider>
  ) : (
    <div id="iso">
      <Overlay positionFixed />
    </div>
  );
});

export default AppWidget;
