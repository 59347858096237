import { ApiGeoUnitFromJSON } from '@ibe/api';
import { ServiceSearchDetailParams } from '@ibe/components';
import { encodeObjectasUrlParam, LoggerFactory, removeUndefinedProps } from '@ibe/services';

export interface RawServiceSearchDetailParams {
  region?: string;
  start?: string;
  end?: string;
  product?: string;
  occupancy?: string;
  item?: string;
  adults?: number;
  children?: string;
  infants?: string;
  childrenAges?: string;
  infantsAges?: string;
  regionCode?: string;
  unitTypeCode?: string;
  serviceTypeCode?: string;
  unitCodes?: string;
  wrk?: string;
  code?: string;
  hotelCode?: string;
  showHotelDetailsPopup?: string;
  salesChannelCode?: string;
  maxDuration?: number;
  maxChildAge?: number;
  maxChildren?: number;
  maxAdults?: number;
}

export interface RawWorkflowServiceSearchDetailParams extends RawServiceSearchDetailParams {
  wrk?: string;
  code?: string;
  salesChannelCode?: string;
}

export interface SearchWidgetParams {
  maxDuration?: number;
  maxChildAge?: number;
  maxChildren?: number;
  maxAdults?: number;
  defaultAdults?: number;
}

export interface LegolandServiceSearchDetailParams extends ServiceSearchDetailParams {
  showHotelDetailsPopup?: string;
}

export class ServiceParamsTransformer {
  static defaultAdult = 2;

  static decode(urlParams: RawServiceSearchDetailParams): Partial<ServiceSearchDetailParams> {
    const logger = LoggerFactory.get('ServiceParamsTransformer');
    const params: Partial<LegolandServiceSearchDetailParams> = {};
    if (!urlParams) {
      return params;
    }
    try {
      params.wrk = urlParams.wrk || undefined;
      params.showHotelDetailsPopup = urlParams.showHotelDetailsPopup || undefined;
      params.salesChannelCode = urlParams.salesChannelCode || undefined;
      params.code = urlParams.code || undefined;
      params.hotelCode = urlParams.hotelCode || undefined;
      params.startDate = urlParams.start || undefined;
      params.endDate = urlParams.end || undefined;
      params.productId = urlParams.item || undefined;
      params.unitTypeCode = urlParams.unitTypeCode || undefined;
      params.unitCodes = urlParams.unitCodes ? JSON.parse(urlParams.unitCodes) : undefined;
      params.serviceTypeCode = urlParams.serviceTypeCode || undefined;
      if (urlParams.occupancy) {
        params.occupancy = JSON.parse(urlParams.occupancy);
      } else if (urlParams.adults || urlParams.children || urlParams.infants) {
        params.occupancy = [
          {
            adults: urlParams.adults
              ? Number(urlParams.adults)
              : ServiceParamsTransformer.defaultAdult,
            children: urlParams.children ? Number(urlParams.children) : 0,
            infants: urlParams.infants ? Number(urlParams.infants) : 0,
            childrenAges: urlParams.childrenAges ? JSON.parse(urlParams.childrenAges) : [],
            infantsAges: urlParams.infantsAges ? JSON.parse(urlParams.infantsAges) : []
          }
        ];
      } else if (!urlParams.occupancy) {
        params.occupancy = [];
      }
      if (urlParams.region) {
        params.region = JSON.parse(urlParams.region);
      } else if (urlParams.regionCode) {
        params.region = ApiGeoUnitFromJSON({
          id: '',
          code: urlParams.regionCode,
          name: '',
          typeCode: '',
          children: {}
        });
      }
    } catch (e) {
      logger.error(e);
    }
    return removeUndefinedProps(params);
  }

  static encode(
    serviceSearchParams: Partial<LegolandServiceSearchDetailParams>,
    searchWidgetParams: SearchWidgetParams
  ): string {
    const obj: RawServiceSearchDetailParams = {
      start: serviceSearchParams.startDate,
      end: serviceSearchParams.endDate,
      showHotelDetailsPopup: serviceSearchParams.showHotelDetailsPopup,
      region: JSON.stringify(serviceSearchParams.region),
      occupancy: JSON.stringify(serviceSearchParams.occupancy),
      item: serviceSearchParams.productId,
      unitTypeCode: serviceSearchParams.unitTypeCode,
      serviceTypeCode: serviceSearchParams.serviceTypeCode,
      unitCodes: JSON.stringify(serviceSearchParams.unitCodes),
      wrk: serviceSearchParams.wrk,
      code: serviceSearchParams.code,
      hotelCode: serviceSearchParams.hotelCode,
      salesChannelCode: serviceSearchParams.salesChannelCode,
      adults: searchWidgetParams.defaultAdults,
      maxAdults: searchWidgetParams.maxAdults,
      maxChildren: searchWidgetParams.maxChildren,
      maxDuration: searchWidgetParams.maxDuration,
      maxChildAge: searchWidgetParams.maxChildAge
    };

    return encodeObjectasUrlParam(removeUndefinedProps(obj));
  }
}
