import { ApiAgency, ApiSeason } from '@ibe/api';
import { SessionStorageKeys } from './QueryUtils';
import MegConfigModel from '../Config/MegConfigModel';

interface ConferenceDataWrapper {
  workflowName: string;
  conferenceData: ApiAgency;
}

abstract class ConferenceUtils {
  public static isAccessVerified(workflowName?: string | null): boolean {
    return !!this.getConferenceData(workflowName);
  }

  public static saveConferenceData(conferenceData: ApiAgency, workflowName: string) {
    const data: ConferenceDataWrapper = {
      workflowName: workflowName,
      conferenceData: conferenceData
    };
    sessionStorage.setItem(SessionStorageKeys.CONFERENCE_DATA, JSON.stringify(data));
  }

  public static getConferenceData(workflowName?: string | null): ApiAgency | null {
    const conferenceDataString = sessionStorage.getItem(SessionStorageKeys.CONFERENCE_DATA);
    if (conferenceDataString) {
      const conferenceDataWrapped: ConferenceDataWrapper = JSON.parse(conferenceDataString);
      if (!workflowName || conferenceDataWrapped.workflowName === workflowName) {
        return conferenceDataWrapped.conferenceData;
      }
    }
    return null;
  }

  public static getConferenceSeason(config: MegConfigModel): ApiSeason | null {
    const confData = ConferenceUtils.getConferenceData();
    const extRef = confData?.externalReferences?.find(
      ext => ext.typeCode === config?.conference?.entryCode
    );
    if (confData?.salesChannel) {
      const season: ApiSeason = {
        startDate: extRef?.validFrom,
        endDate: extRef?.validTo,
        salesChannels: [confData?.salesChannel]
      };
      return season;
    }
    return null;
  }
}

export default ConferenceUtils;
