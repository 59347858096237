import React, { Suspense, useContext, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { useMount } from 'react-use';
import { observer, useLocalStore } from 'mobx-react';
import i18next from 'i18next';
import useCms from '../Hooks/useCms';
import {
  Overlay,
  seasonsStoreContext,
  ServiceSearch,
  OverlayContainer,
  useApi,
  ServiceSearchDetailParams,
  ServiceSearchParams,
  useTheme,
  ThemeProvider,
  Theme,
  useAppService,
  useConfig
} from '@ibe/components';
import { boot, initLanguage } from '../Config/config';
import initI18next from '../Translations/setup';
import { getTheme } from '../Util/utils';
import ExternalParams from '../Config/ExternalParamsContext';
import { ApiItemType, ApiSeason } from '@ibe/api';
import { SessionStorage } from '@ibe/services';
import { SearchWidgetParams } from '../Pages/Params/ServiceParamsTransformer';
import { WidgetProps } from './AppWidget';
import { LoadingOverlay } from '../Components/Spinner/LoadingOverlay';

export interface ExternalSearchWidgetParams {
  serviceSearchParams: Partial<ServiceSearchDetailParams>;
  searchWidgetParams: SearchWidgetParams;
}

export const externalSearchWidgetSessionKey = new SessionStorage<ExternalSearchWidgetParams>(
  'external-search-widget-session-search'
);

const IBESearchWidget = observer(function IBESearchWidget(props: WidgetProps) {
  const {
    packageCode,
    hotelCode,
    salesChannel,
    workflow,
    paxDefinition,
    redirectUrl,
    searchParams: ibeSearchParams,
    triggerSearch
  } = props;

  const appService = useAppService();
  const config = useConfig();
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);
  const [seasons, setSeasons] = useState<ApiSeason[]>([]);
  const [theme, setTheme] = useState<{} | Theme>(useTheme());
  const [
    workflowSearchWidgetParams,
    setWorkflowSearchWidgetParams
  ] = useState<SearchWidgetParams | null>();

  const api = useApi();
  const seasonsContext = useContext(seasonsStoreContext);
  const seasonsStore = useLocalStore(() => seasonsContext);
  seasonsStore.setApi(api);

  const searchParams: Partial<ServiceSearchParams> =
    !!ibeSearchParams && Object.keys(ibeSearchParams).length > 0
      ? {
          code: packageCode,
          wrk: salesChannel,
          salesChannelCode: workflow,
          ...ibeSearchParams
        }
      : {
          code: packageCode,
          wrk: salesChannel,
          salesChannelCode: workflow
        };

  const cmsService = useCms();

  const saveSearchParamsToSession = (params: Partial<ServiceSearchDetailParams>): void => {
    const ibeSearchWidgetParams: ExternalSearchWidgetParams = {
      serviceSearchParams: {
        ...params,
        code: packageCode,
        hotelCode,
        salesChannelCode: salesChannel,
        wrk: workflow
      },
      searchWidgetParams: workflowSearchWidgetParams || {}
    };
    externalSearchWidgetSessionKey.set(ibeSearchWidgetParams);
  };

  useMount(async () => {
    await initI18next(props);
    await boot(true, props);
    const appTheme = await getTheme();
    setTheme(appTheme);

    const lang = initLanguage(i18next.language, config.defaultLanguage);
    appService.setLang(lang);
    appService.setCurrency(config.defaultCurrency);

    seasonsStore.getSeasons().then((res: ApiSeason[]) => {
      setSeasons(
        res.filter(s => (!!s.salesChannels ? s.salesChannels.includes(salesChannel || '') : true))
      );
    });
    cmsService.fetchPackageWorkflow(workflow).then(wrk => {
      setWorkflowSearchWidgetParams({
        defaultAdults: paxDefinition?.defaultAdults
          ? paxDefinition.defaultAdults
          : wrk.numberOfAdults,
        maxAdults: paxDefinition?.maxAdults ? paxDefinition.maxAdults : wrk.maxNumberOfAdults,
        maxChildAge: paxDefinition?.maxChildAge ? paxDefinition.maxChildAge : wrk.maxChildAge,
        maxChildren: paxDefinition?.maxChildren
          ? paxDefinition?.maxChildren
          : wrk.maxNumberOfChildren,
        maxDuration: paxDefinition?.maxDuration ? paxDefinition.maxDuration : wrk.maxTripDuration
      });
    });

    setBootstrappingFinished(true);
  });

  const onSubmit = (params: ServiceSearchParams): void => {
    saveSearchParamsToSession(params);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return bootstrappingFinished ? (
    <ThemeProvider theme={theme}>
      <div id="iso">
        <Suspense fallback={<LoadingOverlay />}>
          <MemoryRouter>
            <ExternalParams {...props}>
              <OverlayContainer>
                {workflowSearchWidgetParams && (
                  <ServiceSearch
                    itemType={ApiItemType.HOTEL}
                    searchParams={searchParams}
                    onSubmit={onSubmit}
                    withProductSelect={false}
                    withChildAgePicker
                    withRoomCountSelector={false}
                    showLabels
                    showPlaceholder
                    withGroupRequestForm={false}
                    seasons={seasons}
                    hideDestination
                    maxChildren={workflowSearchWidgetParams.maxChildren}
                    maxChildAge={workflowSearchWidgetParams.maxChildAge}
                    maxAdults={workflowSearchWidgetParams.maxAdults}
                    maxCapacity={100}
                    maxDateRangeSpan={
                      workflowSearchWidgetParams.maxDuration !== null
                        ? workflowSearchWidgetParams.maxDuration
                        : undefined
                    }
                    defaultValuesConfig={{
                      ignoreDatesDefaultsValues: true,
                      ignorePaxSelectionDefaultValues: true
                    }}
                    triggerSearch={triggerSearch}
                  />
                )}
              </OverlayContainer>
            </ExternalParams>
          </MemoryRouter>
        </Suspense>
      </div>
    </ThemeProvider>
  ) : (
    <div id="iso">
      <Overlay positionFixed />
    </div>
  );
});

export default IBESearchWidget;
